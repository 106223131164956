import React from 'react'
import Page from '../components/Homemade/RatingsPage/SubmitRatingsPage'
import generatePagePropsOnBuildTime from '../helpers/generatePagePropsOnBuildTime'

const SubmitRatings = (props) => {
    return <Page {...generatePagePropsOnBuildTime(props, {
        product: {
            _id: {
                _id: 'test'
            }
        },
        isLike: false,
        order: {
            _id: 'test',
            user: {
                _id: 'test'
            },
            shop: {
                _id: 'test'
            },
        }
    })} />
}

export default SubmitRatings